// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill';
import Promise from 'es6-promise';
Promise.polyfill();
import Vue from 'vue';
import App from './App';
import router from './router';
// element-ui组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// axios请求方法
import axios from 'axios';
Vue.prototype.$axios = axios;
// cookies存储
import Cookies from 'js-cookie';
// jquery引入使用
import $ from 'jquery';
// common公共组件
import global from './components/common.vue';
import '@/assets/css/common.scss';
Vue.prototype.COMMON = global;

// 请求地址
axios.defaults.baseURL = global.baseUrl;

// let apiUrl = '';
// let HOST = process.env.HOST;
// HOST = HOST === 'prod' ? '.canhelpdx.com' : '.canhelpgenomics.cn';
// apiUrl = 'https://quiz' + HOST + '/api/';
// axios.defaults.baseURL = apiUrl;

//请求超时30s
axios.defaults.timeout = 30000;
// vue 会提供很多警告来帮你对付常见的错误与陷阱,关闭提示
Vue.config.productionTip = false
/* 是否有请求正在刷新token */
window.isRefreshing = false
/*被挂起的请求数组*/
let resolveArray = [];
/*push所有请求到数组中*/
function pushResolveArray(cb) {
	resolveArray.push(cb);
}
/*刷新请求（resolveArray数组中的请求得到新的token之后会自执行，用新的token去请求数据）*/
function onRrefreshed(token) {
	resolveArray.map(cb => cb(token));
}
// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use(function(config) {
	//当前是否有网络
	if (navigator.onLine == true) {
		let accessToken = Cookies.get('accessToken');
		let scope = Cookies.get('scope')
		config.headers['Content-Type'] = 'application/json;charset=UTF-8';
		// 判断请求若是获取部门人员信息，则修改baseURL
		if(config.url=="/hr/employee/list/byDepartment"){
			config.baseURL=global.mabaseUrl
		}
		else if(config.headers.exam){
			config.baseURL= global.clientUrl;
		}
		else{
			config.baseURL= global.baseUrl;
		}
		//请求刷新接口不需要另外刷新
		if (config.url == "/oauth/refreshToken") {
			return config;
		}
		//请求退出登录接口，不需要刷新token，但也要有header认证
		if (config.url == "/oauth/logout") {
			config.headers.Authorization = 'Bearer' + ' ' + accessToken;
			return config;
		}
		if (accessToken) {
			if (scope != undefined && (scope.indexOf('quiz') >= 0)) {
				config.headers.Authorization = 'Bearer' + ' ' + accessToken;
				if (config.url != "/oauth/refreshToken") {
					config.headers.Authorization = 'Bearer' + ' ' + accessToken;
				}
				let expires = Cookies.get('expires');
				let nowTime = new Date().getTime() / 1000;
				let cha = expires - nowTime;
				// 300   5五分钟  即将过期，挂起当前请求
				if ((-7200 <= cha) && (cha <= 300 ) && (!window.isRefreshing)) {
					window.isRefreshing = true;
					axios.post("/oauth/refreshToken", {
							refreshToken: Cookies.get('refreshToken')
						})
						.then(res => {
							window.isRefreshing = false;
							if (res.data.accessToken) {
								onRrefreshed(res.data.accessToken);
								resolveArray = [];
								Cookies.set('expires', res.data.expires, {
									domain: global.domain,
									expires: 1,
									path: '',
									secure: global.httpsSecure
								});
								Cookies.set('scope', res.data.scope, {
									domain: global.domain,
									expires: 1,
									path: '',
									secure: global.httpsSecure
								});
								Cookies.set('accessToken', res.data.accessToken, {
									domain: global.domain,
									expires: 1,
									path: '',
									secure: global.httpsSecure
								});
								Cookies.set('refreshToken', res.data.refreshToken, {
									domain: global.domain,
									expires: 1,
									path: '',
									secure: global.httpsSecure
								});
							}
						})
						.catch(function(error) {})
					let retry = new Promise((resolve, reject) => {
						pushResolveArray((token) => {
							config.headers.Authorization = 'Bearer' + ' ' + token;
							resolve(config)
						})
					})
					return retry;
				}
			} else {
				Vue.prototype.$alert('登录失效, 请重新登录!', '提示', {
					confirmButtonText: '确定',
					type: 'warning',
					callback: action => {
						Cookies.remove('accessToken', {
							path: '',
							domain: global.domain
						});
						Cookies.remove('refreshToken', {
							path: '',
							domain: global.domain
						});
						Cookies.remove('expires', {
							path: '',
							domain: global.domain
						});
						Cookies.remove('scope', {
							path: '',
							domain: global.domain
						});
						localStorage.removeItem("userName");
						localStorage.removeItem("permission");
						window.location.href = global.canhelpUrl + "?redirect_uri=" + global
							.quizUrl + "&client_id=" + global.clientId +
							"&power=" + global.power + "&verSion=" + global.verSion;
					}
				});

			}
		}
		return config;
	} else {
		config.handled = true;
		return Promise.reject("请检查当前网络!");
	}
});

// 添加响应拦截器
axios.interceptors.response.use(function(response) {
	//下载
	const headers = response.headers
	if (headers['content-type'] === 'application/x-download;charset=UTF-8') {
		return response
	}
	if (response.status === 200) {
		if (!response.data.size && response.config.responseType == "blob") {
			//下载失败
			return Promise.reject(response);
		} else if (response.data.status == 0) {
			return response.data
		} else {
			if (response.data.data.message == 'NO_PERMISSION') {
				let that = this;
				axios.get("/me")
					.then(function(res) {
						localStorage.setItem("userName", res.data.user.name);
						if (res.data.permission) {
							var arr = JSON.stringify(res.data.permission);
							localStorage.setItem("permission", arr);
						}
					})
					.catch(function(err) {});
				return Promise.reject(response.data.data.desc);
			} else if (response.data.data.message == 'INVALID_CODE' || response.data.data.message ==
				'INVALID_TOKEN' || response.data
				.data.message == 'EMPTY_TOKEN' || response.data.data.message == 'TOKEN_EXPIRED' || response.data
				.data.message ==
				'INVALID_SIGN' || response.data.data.message == 'INVALID_REFRESH_TOKEN' || response.data.data
				.message ==
				'TOKEN_IP_CONFLICT') {
				Vue.prototype.$alert('登录失效, 请重新登录!', '提示', {
					confirmButtonText: '确定',
					type: 'warning',
					callback: action => {
						Cookies.remove('accessToken', {
							path: '',
							domain: global.domain
						});
						Cookies.remove('refreshToken', {
							path: '',
							domain: global.domain
						});
						Cookies.remove('expires', {
							path: '',
							domain: global.domain
						});
						Cookies.remove('scope', {
							path: '',
							domain: global.domain
						});
						localStorage.removeItem("userName");
						localStorage.removeItem("permission");
						window.location.href = global.canhelpUrl + "?redirect_uri=" + global
							.quizUrl + "&client_id=" + global.clientId +
							"&power=" + global.power + "&verSion=" + global.verSion;
					}
				});
				return Promise.reject("登录失效, 请重新登录!");
			} else {
				return Promise.reject(response.data.data.desc);
			}
		}
	} else {
		return Promise.reject(response);
	}
}, function(error) {
	if (!navigator.onLine && error.config && !error.config.handled) {
		return Promise.reject("请检查当前网络!");
	} else if (error.status == 404) {
		router.push('/notFound');
	}else if(error.config &&error.config.url=="/quiz/upload" && error.message=="Network Error"){
		return Promise.reject("请检查文件是否更改!");
	}else {
		if (error.config && !error.config.handled) {
			return Promise.reject("请求超时!");
		}
	}
	return Promise.reject(error);
});

// 解决导航当前路由报错问题
const originalPush = router.push
router.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	components: {
		App
	},
	template: '<App/>'
})
