<template>
	<div id="app">
		<router-view></router-view>
		<el-dialog
		  title="提示"
		  :visible.sync="dialogVisible"
		  width="400px"
		  :before-close="handleClose"
          :close-on-click-modal="false">
		    <el-button size="small" type="warning" @click="goManage">前往管理端</el-button>
		    <el-button size="small" type="primary" @click="goClient">前往考试端</el-button>
		</el-dialog>
	</div>
</template>

<script>
	import Cookies from 'js-cookie';
	export default {
		name: 'App',
		// 父组件中返回要传给下级的数据，reload暴露给子组件
		provide() {
			return {
				reloadApp: this.getMeData
			}
		},
		data(){
			return{
				dialogVisible:false,//弹框询问去向
				// 是否已经选择去向
				deriction:false
			}
		},
		// created() {
		// 	var that=this;
		// 	that.getCode();
		// },
		watch:{
		    $route(to,from){
		      console.log();//到哪去
			  if(to.name==='Index'){
				  this.getCode();
			  }
		    }
		},
		methods: {
			// 认证重定向之后，返回code
			getCode() {
				let _this = this;
				var arr = window.location.search.substr(1).split("=");
				if (Cookies.get("accessToken")) {
					if(!localStorage.getItem("userName")){
						_this.getMeData();
					}else{
						if(localStorage.getItem("permission") && JSON.parse(localStorage.getItem("permission")).admin){
							setTimeout(function(){
								if(_this.$route.path=="/" && _this.$route.name=="Index"){
									_this.dialogVisible=true;
								}
							},100)
						}else{
							_this.$router.push('/quizSystem');
						}
					}
				} else {
					if (arr.includes("code")) {
						var https = window.location.href;
						var cs = https.split("?")[1];
						var cs_arr = cs.split("&");
						var cs = {};
						for (var i = 0; i < cs_arr.length; i++) {
							cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
						}
						localStorage.removeItem("userName");
						localStorage.removeItem("permission");
						_this.$axios
							.get("/oauth/codeExchange/" + cs.code)
							.then(function(res) {
								if (res.data.accessToken) {
									Cookies.set("expires", res.data.expires, {
										domain: _this.COMMON.domain,
										expires: 1,
										path: "",
										secure: _this.COMMON.httpsSecure
									});
									Cookies.set("scope", res.data.scope, {
										domain: _this.COMMON.domain,
										expires: 1,
										path: "",
										secure: _this.COMMON.httpsSecure
									});
									Cookies.set("accessToken", res.data.accessToken, {
										domain: _this.COMMON.domain,
										expires: 1,
										path: "",
										secure: _this.COMMON.httpsSecure
									});
									Cookies.set("refreshToken", res.data.refreshToken, {
										domain: _this.COMMON.domain,
										expires: 1,
										path: "",
										secure: _this.COMMON.httpsSecure
									});
									_this.getMeData();
								}
							})
							.catch(function(err) {
								window.location.href =
									_this.COMMON.canhelpUrl +
									"?redirect_uri=" +
									_this.COMMON.quizUrl +
									"&client_id=" +
									_this.COMMON.clientId +
									"&power=" +
									_this.COMMON.power + "&verSion=" + _this.COMMON.version;
							});
					} else {
						window.location.href =
							_this.COMMON.canhelpUrl +
							"?redirect_uri=" +
							_this.COMMON.quizUrl +
							"&client_id=" +
							_this.COMMON.clientId +
							"&power=" +
							_this.COMMON.power + "&verSion=" + _this.COMMON.version;
						return false;
					}
				}
			},
			getMeData() {
				let _this = this;
				_this.loading = true;
				_this.$axios
					.get("/me")
					.then(function(res) {
						_this.loading = false;
						localStorage.setItem("userName", res.data.user.name);
						//存储权限
						if (res.data.permission) {
							if(res.data.permission.admin){
								if(_this.$route.path=="/" && _this.$route.name=="Index"){
									_this.dialogVisible=true;
								}
							}else{
								_this.$router.push('/quizSystem');
							}
							var arr = JSON.stringify(res.data.permission);
							localStorage.setItem("permission", arr);
						}else{
							_this.$router.push('/quizSystem');
						}
					})
					.catch(function(err) {
						_this.loading = false;
					});
			},
			// 前往管理端
			goManage(){
				this.dialogVisible=false;
				this.deriction=true;
				this.$router.push('/home');
			},
			// 前往考试端
			goClient(){
				this.dialogVisible=false;
				this.deriction=true;
				this.$router.push('/quizSystem');
			},
			// 关闭之前
			handleClose(){
				var that=this;
				if(!that.deriction){
					that.deriction=true;
					that.$router.push('/home');
				}
				that.dialogVisible=false;
			}
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		background-color: #F0F3F4;
	}
</style>
