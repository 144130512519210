<template>
	<div></div>
</template>
<script>
	// 为true0本地，1线上测试，2正式环境
	var isDev = 1;
	var power = "zh";
	var version = "";
	if (isDev == "0") {
		var baseUrl = 'https://quiz.canhelpgenomics.cn/api/manage';//管理端请求
		var clientUrl = 'https://quiz.canhelpgenomics.cn/api/client';//考试端请求
		var mabaseUrl='https://ma.canhelpgenomics.cn/api';//获取员工部门时跨域请求
		var quizUrl = "http://quiz.dev.canhelpdx.com";
		var canhelpUrl = "http://auth.dev.canhelpdx.com/login";
		var canChangeUrl = "http://auth.dev.canhelpdx.com/changePwd";
		var domain = ".dev.canhelpdx.com";
		var clientId = "04QGZeFE2q0dbWWi";
		var httpsSecure = false;
	} else if (isDev == "1") {
		var baseUrl = 'https://quiz.canhelpgenomics.cn/api/manage';
		var clientUrl = 'https://quiz.canhelpgenomics.cn/api/client';//考试端请求
		var mabaseUrl='https://ma.canhelpgenomics.cn/api';
		var quizUrl = "https://quiz.canhelpgenomics.cn";
		var canhelpUrl = "https://auth.canhelpgenomics.cn";
		var canChangeUrl = "https://auth.canhelpgenomics.cn/changePwd";
		var domain = ".canhelpgenomics.cn";
		var clientId = "04QGZeFE2q0dbWWi";
		var httpsSecure = true;
	} else {
		var baseUrl = 'https://quiz.canhelpdx.com/api/manage';
		var clientUrl = 'https://quiz.canhelpdx.com/api/client';//考试端请求
		var mabaseUrl='https://ma.canhelpdx.com/api';
		var quizUrl = "https://quiz.canhelpdx.com";
		var canhelpUrl = "https://auth.canhelpdx.com/login";
		var canChangeUrl = "https://auth.canhelpdx.com/changePwd";
		var domain = ".canhelpdx.com";
		var clientId = "5d9gYvPGOc4YwzQR";
		var httpsSecure = true;
	}
	// 邮箱验证
	function checkEmail(val) {
		var regu =
			"^(([0-9a-zA-Z]+)|([0-9a-zA-Z]+[_.0-9a-zA-Z-]*[0-9a-zA-Z]+))@([a-zA-Z0-9-]+[.])+([a-zA-Z]{2}|net|NET|com|COM|gov|GOV|mil|MIL|org|ORG|edu|EDU|int|INT)$"
		var re = new RegExp(regu);
		if (val.search(re) != -1) {
			return true;
		} else {
			return false;
		}
	}
	// 不能输入空
	function inputEmpet(val) {
		return val.replace(/\s+/g, "");
	}
	// 只能输入正整数(但此方法可以为0)
	function inputNumber(val) {
		return val.replace(/\D/g, '');
	}
	// 规格部分小数点后两位0-9999999
	function specs(val){
		val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
		val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
		val = val
			.replace(".", "$#$")
			.replace(/\./g, "")
			.replace("$#$", ".");
		val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入6个小数
		if (val.indexOf(".") < 0 && val != "") {
			val = parseFloat(val);
		}
		var rnaValue = val;
		if (rnaValue < 0 || rnaValue > 9999999) {
			val = "";
		}
		return val;
	}
	// rna判断
	function rnaCheck(val){
		val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
		val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
		val = val
			.replace(".", "$#$")
			.replace(/\./g, "")
			.replace("$#$", ".");
		val = val.replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d).*$/, "$1$2.$3"); //只能输入6个小数
		if (val.indexOf(".") < 0 && val != "") {
			val = parseFloat(val);
		}
		var rnaValue = val;
		if (rnaValue < 0 || rnaValue > 5000) {
			val = "";
		}
		return val;
	}
	// 获取最大值减去最小值
	function chaNumber(label, val) {
		var max = Math.max.apply(Math, val.map(function(item) {
			if (label == 'ic') {
				return item.ic
			} else if (label == 's1') {
				return item.s1
			} else if (label == 's2') {
				return item.s2
			} else if (label == 's3') {
				return item.s3
			} else if (label == 's4') {
				return item.s4
			} else if (label == 's5') {
				return item.s5
			} else if (label == 's6') {
				return item.s6
			} else if (label == 's7') {
				return item.s7
			}
		}));
		var min = Math.min.apply(Math, val.map(function(item) {
			if (label == 'ic') {
				return item.ic
			} else if (label == 's1') {
				return item.s1
			} else if (label == 's2') {
				return item.s2
			} else if (label == 's3') {
				return item.s3
			} else if (label == 's4') {
				return item.s4
			} else if (label == 's5') {
				return item.s5
			} else if (label == 's6') {
				return item.s6
			} else if (label == 's7') {
				return item.s7
			}
		}));
		var values = parseFloat(max) - parseFloat(min);
		// console.log('max：' + max);
		// console.log('min：' + min);
		if ((values.toString().indexOf(".") > -1) && (values.toString().split(".")[1].length > 3)) {
			values = values.toFixed(3);
		}
		return values
	}
	// 时长
	function limitCheck(val) {
		var that = this;
		var Value = val.replace(/[^\d]/g, "");
		return Value;
		
	}
	//床位数限制 0, 100000
	function limitBed(val) {
		var that = this;
		var limitValue = val.replace(/[^\d]/g, "");
		if (
			parseInt(limitValue) < 0 ||
			parseInt(limitValue) > 100000
		) {
			return "";
		} else {
			return limitValue;
		}
	}
	// 小数点后最多三位
	function purityCheck(val) {
		var that = this;
		val = val.replace(/[^\d.]/g, "");
		val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
		val = val.replace(
			/^(\-)*(\d+)\.(\d\d\d).*$/,
			"$1$2.$3"
		); //只能输入6个小数
		var amountValue = val;
		if (amountValue < 0) {
			val = "";
		} else {
			var posDot = amountValue.indexOf(".");
			if (posDot > -1) {
				val = amountValue.substring(0, posDot) + amountValue.substring(posDot, posDot + 4);
			}
		}
		return val;
	};
	// 小数点后最多六位 
	function icCheck(val) {
		var that = this;
		val = val.replace(/[^\d.]/g, "");
		val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
		val = val.replace(
			/^(\-)*(\d+)\.(\d\d\d\d\d\d).*$/,
			"$1$2.$3"
		);
		var amountValue = val;
		if (amountValue < 0) {
			val = "";
		} else {
			var posDot = amountValue.indexOf(".");
			if (posDot > -1) {
				val = amountValue.substring(0, posDot) + amountValue.substring(posDot, posDot + 7);
			}
		}
		return val;
	};
	// 浮点数相加：网上搜素的方法
	function FloatAdd(arg1, arg2) {
		var r1, r2, m;
		try {
			r1 = arg1.toString().split(".")[1].length
		} catch (e) {
			r1 = 0
		}
		try {
			r2 = arg2.toString().split(".")[1].length
		} catch (e) {
			r2 = 0
		}
		m = Math.pow(10, Math.max(r1, r2));
		// return ((arg1 * m + arg2 * m) / m).toFixed(2);
		return (arg1 * m + arg2 * m) / m;
	};
	// 浮点数相减：网上搜素的方法
	function FloatSub(arg1, arg2) {
		var r1, r2, m, n;
		try {
			r1 = arg1.toString().split(".")[1].length
		} catch (e) {
			r1 = 0
		}
		try {
			r2 = arg2.toString().split(".")[1].length
		} catch (e) {
			r2 = 0
		}
		m = Math.pow(10, Math.max(r1, r2));
		//动态控制精度长度
		n = (r1 = r2) ? r1 : r2;
		// return ((arg1 * m - arg2 * m) / m).toFixed(2);
		return (arg1 * m - arg2 * m) / m;
	};
	// 肿瘤组织比例
	function percentInput(val){
		//只能输入数字
		val = val.replace(/[^\d.]/g, "");
		val = val.replace(/\.{2,}/g, ".");
		val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$",
			".");
		//只能输入1个小数
		val = val.replace(/^(\-)*(\d+)\.(\d).*$/, "$1$2.$3");
		
		if (val.indexOf(".") < 0 && val != "") {
			val = parseFloat(val);
		}
		var tumorValue = val;
		if (tumorValue < 0 || tumorValue > 100) {
			val = "";
		}
		return val;
	}
	// 日期格式转换
	function getDataMethods(val,date){
		var that = this;
		var Y = date.getFullYear() + '-';
		var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
		var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
		var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
		var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes())+ ':';
		var s = date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds(); 
		if(val=='getdate'){
			return Y+M+D;
		}else{
			return Y+M+D+h+m+s;
		}
	}
	// 当月的最后0-11 => 1-12 59:59
	function monthControl(date){
		var that = this;
		var Y = date.getFullYear();
		var M = date.getMonth()+1;
		var Mval=M +1;
		if((M +1) > 12){
			return Y+1+'-'+'1';
		}else{
			return Y+'-'+ Mval;
		}
	}
	// 数组对象的排序
	function compare(property){
	    return function(a,b){
	        var value1 = a[property];
	        var value2 = b[property];
	        return value1 - value2;
	    }
	}
	// 倒计时时分秒
	function formatSeconds(value) {
		var secondTime = parseInt(value);// 秒
		var minuteTime = 0;// 分
		var hourTime = 0;// 小时
		if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
			//获取分钟，除以60取整数，得到整数分钟
			minuteTime = parseInt(secondTime / 60);
			//获取秒数，秒数取佘，得到整数秒数
			secondTime = parseInt(secondTime % 60);
			//如果分钟大于60，将分钟转换成小时
			if(minuteTime > 60) {
				//获取小时，获取分钟除以60，得到整数小时
				hourTime = parseInt(minuteTime / 60);
				//获取小时后取佘的分，获取分钟除以60取佘的分
				minuteTime = parseInt(minuteTime % 60);
			}
		}
		var result = "" + parseInt(secondTime) + "秒";

		if(minuteTime > 0) {
			result = "" + parseInt(minuteTime) + "分" + result;
		}
		if(hourTime > 0) {
			result = "" + parseInt(hourTime) + "小时" + result;
		}
		return result;
	}
	// 判断数组对象中是否某个key有值
	function findElem(array,val) {
	    for (var i = 0; i < array.length; i++) {
			var obj="array[i]."+val;
	        if (eval(obj)) {
	            return i; //返回当前索引值
	        }
	    }
	    return -1;
	}
	// 只有本地开发环境打印console.log
	console.log = (function (log) {
	        return isDev ===0? log : function() {}
	}(console.log))
	// 暴露出这些属性和方法
	export default {
		name: "Common",
		// commonFun
		isDev,
		baseUrl,
		clientUrl,
		mabaseUrl,
		quizUrl,
		canhelpUrl,
		clientId,
		canChangeUrl,
		domain,
		httpsSecure,
		version,
		power,
		inputEmpet,
		inputNumber,
		rnaCheck,
		chaNumber,
		limitCheck,
		limitBed,
		purityCheck,
		icCheck,
		FloatAdd,
		FloatSub,
		percentInput,
		checkEmail,
		specs,//规格的判断
		getDataMethods,
		monthControl,
		compare,
		formatSeconds,
		findElem,
	};
</script>
<style scoped>
</style>
