import VueRouter from 'vue-router' //安装引入vue-router
import Vue from 'vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Index',
		component: resolve => require(['@/components/index'], resolve),
	}, {
		path: '/home',
		name: 'Home',
		component: resolve => require(['@/components/manage/home'], resolve),
		children: [{ // 考试范围
			path: '/quizScope',
			name: 'QuizScope',
			component: resolve => require(['@/components/manage/quizScope'], resolve)
		}, { // 试题上传
			path: '/quizUpload',
			name: 'QuizUpload',
			component: resolve => require(['@/components/manage/quizUpload'], resolve)
		}, { // 试题列表
			path: '/quizList',
			name: 'QuizList',
			component: resolve => require(['@/components/manage/quizList'], resolve)
		}, { // 试卷管理
			path: '/exam',
			name: 'Exam',
			component: resolve => require(['@/components/manage/exam'], resolve)
		}]
	},
	{ // 试卷规则
		path: '/ruleList',
		name: 'RuleList',
		redirect: "/home",
		component: resolve => require(['@/components/manage/ruleList'], resolve)
	},
	{ // 参考人员
		path: '/assignList',
		name: 'AssignList',
		redirect: "/home",
		component: resolve => require(['@/components/manage/assignList'], resolve)
	},
	// 考试端
	{
		path: "/quizSystem",
		name: "QuizSystem",
		component: resolve => require(['@/components/client/quizSystem'], resolve)
	},
	// 考试端nav组件
	{
		path: "/examNav",
		name: "ExamNav",
		redirect: "/quizSystem",
		component: resolve => require(['@/components/client/nav'], resolve)
	},
	// 考试端nav组件
	{
		path: "/form",
		name: "Form",
		redirect: "/quizSystem",
		component: resolve => require(['@/components/client/form'], resolve)
	},
	// 界面不存在情况下
	{
		path: "*",
		redirect: "/notFound",
		name: "NotFound",
		component: resolve => require(['@/components/notFound'], resolve)
	}
]

/**
 * 初始化路由 配置
 */
const router = new VueRouter({
	routes,
	mode: "history"
});

/**
 * 导出路由
 */
export default router
